const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },

  {
    path: "/beavers/:id",
    component: "Beavers",
  },

  {
    path: "/mobileInstructions",
    exact: true,
    component: "Mobile",
  },
];

export default routes;
