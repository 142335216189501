import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";

import Router from "./router";

const App = () => {

  function getLibrary(provider) {
    return new Web3(provider)
  };

  return(
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Web3ReactProvider>
)};

ReactDOM.render(<App />, document.getElementById("root"));
