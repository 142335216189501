import { createGlobalStyle } from "styled-components";
import { PRIMARY, SECONDARY, TERTIARY, ACCENT, HOVER } from "./color";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Roboto Regular";
        src: url("/fonts/Roboto-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto Bold";
        src: url("/fonts/Roboto-Bold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto Light";
        src: url("/fonts/Roboto-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto Medium";
        src: url("/fonts/Roboto-Medium.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Monoton";
        src: url("/fonts/Monoton-Regular.ttf") format("truetype");
        font-style: normal;
    }

    body,
    html,
    a {
        font-family: 'Roboto Regular', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: ${PRIMARY};
        overflow-x: hidden;
    }

    a:hover {
        color: ${ACCENT};
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1 {
        font-family: 'Roboto Bold', serif;
        color: #f1f1f1;
        font-size: 4em;
        line-height: 1;
        text-transform: uppercase;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }
    h2,
    h3,
    h4,
    h5 {
        font-family: 'Roboto Bold', serif;
        color: ${TERTIARY};
        font-size: 32px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 20px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 18px;
        }
    }

    h6 {
        font-family: 'Roboto Bold', serif;
        color: ${TERTIARY};
        font-size: 22px;
        line-height: 1.50;
        text-transform: uppercase;
        padding-bottom: 8px;

        @media only screen and (max-width: 890px) {
          font-size: 20px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 18px;
        }
    }

    p {
        color: ${TERTIARY};
        font-size: 18px;        
        line-height: 1.41;
        font-family: 'Roboto Light', sans-serif;
    }

    small {
        color: ${TERTIARY};
        font-size: 14px;        
        line-height: 1.41;
        font-family: 'Roboto Light', sans-serif;
    }

    span {
        color: ${TERTIARY};
        font-size: 18px;        
        line-height: 1.41;
        font-family: 'Roboto Medium', sans-serif;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: ${ACCENT};

        :hover {
            color: ${ACCENT};
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .ant-card {
        padding: 1em;
        background-color: #1c1c1c;
        gap: 1em;
        border-radius: 1em;
        box-shadow: 0 0.5px 1px #d24545, 0 0.5px 7px #d24545;
        width: 240px;
        border: 0.5px solid #d24545;
        @media only screen and (max-width: 414px) {
            width: 340px;
        }
    }

    .ant-card-meta-title {
        color: ${TERTIARY};
        font-family: 'Roboto Bold', serif;
    }
    .ant-card-meta-description {
        color: ${TERTIARY};
    }

    .ant-card-meta-detail {
        text-align: center;
    }

    .ant-timeline-item-head{
      background-color: ${PRIMARY};
    }

    .ant-btn span{
        color: ${PRIMARY};
    }

    .ant-btn-primary {
        background-color: ${ACCENT};
        border-color: ${ACCENT};
        span{
            color: ${TERTIARY};
        }
    }


    @keyframes neon1 {
        from {
          text-shadow: 0 0 10px #fbb1bd, 0 0 20px #fbb1bd, 0 0 30px #fbb1bd, 0 0 40px #ff1177,
            0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
        }
        to {
          text-shadow: 0 0 5px #fbb1bd, 0 0 10px #fbb1bd, 0 0 15px #fbb1bd, 0 0 20px #ff1177,
            0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
        }
      }


    
`;

// animation: neon1 3s ease-in-out infinite alternate;


